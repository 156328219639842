'use client';

import {
	Dispatch,
	PropsWithChildren,
	createContext,
	useContext,
	useReducer,
} from 'react';
import { produce } from '@/globals/helpers/immer';

export type AccordionItemID = number | string;

export type AccordionItemsState = Map<AccordionItemID, boolean>;

type AccordionState = {
	defaultItemOpenedState: boolean;
	items: AccordionItemsState;
};

const AccordionContext = createContext<
	| (AccordionState & {
			dispatchOpenedItems: Dispatch<
				Parameters<typeof accordionStateReducer>[1]
			>;
	  })
	| undefined
>(undefined);

const isItemOpened = (accordionState: AccordionState, id: AccordionItemID) => {
	return accordionState.items.get(id) ?? accordionState.defaultItemOpenedState;
};

const accordionStateReducer = produce(
	(
		accordionStateDraft: AccordionState,
		action:
			| { type: 'toggle'; id: AccordionItemID }
			| { type: 'openAll' }
			| { type: 'closeAll' },
	) => {
		switch (action.type) {
			case 'toggle':
				accordionStateDraft.items.set(
					action.id,
					!isItemOpened(accordionStateDraft, action.id),
				);

				return;

			case 'openAll':
				accordionStateDraft.defaultItemOpenedState = true;
				accordionStateDraft.items.clear();
				return;

			case 'closeAll':
				accordionStateDraft.defaultItemOpenedState = false;
				accordionStateDraft.items.clear();
				return;
		}
	},
);

export const AccordionProvider = ({
	children,
	defaultOpened = new Set<AccordionItemID>(),
}: PropsWithChildren<{ defaultOpened?: Set<AccordionItemID> }>) => {
	const [accordionState, dispatchOpenedItems] = useReducer(
		accordionStateReducer,
		{
			defaultItemOpenedState: false,
			items: new Map([...defaultOpened].map((id) => [id, true])),
		},
	);

	return (
		<AccordionContext.Provider
			value={{ ...accordionState, dispatchOpenedItems }}
		>
			{children}
		</AccordionContext.Provider>
	);
};

export const useAccordionItemOpened = (id: AccordionItemID) => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useAccordionItemOpened must be used within a AccordionProvider',
		);
	}

	return isItemOpened(context, id);
};

export const useAreAllItemsOpened = (itemsCount: number) => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useAccordionOpenedItemsCount must be used within a AccordionProvider',
		);
	}

	const { items, defaultItemOpenedState } = context;

	const areAllKnownItemsOpened = [...items.values()].every(Boolean);

	if (defaultItemOpenedState === true) {
		return areAllKnownItemsOpened;
	}

	return items.size === itemsCount && areAllKnownItemsOpened;
};

export const useAreAllItemsClosed = (itemsCount: number) => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useAccordionOpenedItemsCount must be used within a AccordionProvider',
		);
	}

	const { items, defaultItemOpenedState } = context;

	const areAllKnownItemsClosed = [...items.values()].every(
		(item) => item === false,
	);

	if (defaultItemOpenedState === false) {
		return areAllKnownItemsClosed;
	}

	return items.size === itemsCount && areAllKnownItemsClosed;
};

export const useDispatchAccordion = () => {
	const context = useContext(AccordionContext);
	if (context == null) {
		throw new Error(
			'useDispatchAccordion must be used within a AccordionProvider',
		);
	}

	return context.dispatchOpenedItems;
};
