'use client';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { textBlock } from '@/uiPrimitives/typography/text';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import {
	useAreAllItemsClosed,
	useDispatchAccordion,
} from './AccordionProvider.client';
import { translateInline } from '@/globals/translate/translateInline';

export const AccordionToggleButtons = ({
	itemsCount,
	className,
}: {
	itemsCount: number;
	className?: string;
}) => {
	const dispatchAccordion = useDispatchAccordion();
	const areAllItemsClosed = useAreAllItemsClosed(itemsCount);

	if (itemsCount <= 1) return null;

	return (
		<div
			className={classes(
				placement({ alignSelf: 'end' }),
				occupy({ blockSize: '0px', alignItems: 'end' }),
				className,
			)}
		>
			<div
				className={classes(
					box({
						paddingBlockEnd: '1 | h3',
					}),
				)}
			>
				<button
					className={classes(
						textBlock({
							textDecorationLine: 'underline',
							color: 'positiveBlue',
							capSize: '-1 | small',
						}),
					)}
					onClick={() => {
						if (areAllItemsClosed) {
							dispatchAccordion({
								type: 'openAll',
							});
						} else {
							dispatchAccordion({ type: 'closeAll' });
						}
					}}
					{...addTestSelector('uiToggleAllAccordionsButton')}
				>
					{areAllItemsClosed
						? translateInline({
								en: 'Open all',
								nl: 'Alles openen',
							})
						: translateInline({
								en: 'Close all',
								nl: 'Alles sluiten',
							})}
				</button>
			</div>
		</div>
	);
};
