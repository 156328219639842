import { translateInline } from '@/globals/translate/translateInline';
import { hiddenAccessible } from '@/uiPrimitives/utility/display.css';

export const getAccordionActionLabel = (isOpened: boolean) => {
	if (isOpened) {
		return translateInline({
			en: 'Close',
			nl: 'Sluiten',
		});
	}

	return translateInline({
		en: 'Open',
		nl: 'Openen',
	});
};

export const A11YAccordionActionLabel = ({
	isOpened,
}: {
	isOpened: boolean;
}) => {
	return (
		<span className={hiddenAccessible}>
			{getAccordionActionLabel(isOpened)}
		</span>
	);
};
